import { useContentfulBlogPost } from 'hooks/useContentfulBlogPost';
import { stringToAnchorId } from 'helpers/stringToAnchorId';

import { Container, Section, SectionImage } from 'components/Container';
import { BlogLink } from 'components/Links';
import { BlogPostImage } from './BlogPostImage.styled';

import type { FC } from 'react';

export interface BlogCalloutProps {
  slug: string;
  title: string;
  /** if not passed, we will use the `blogPost.title` as a fallback. */
  description?: string;
  linkText?: string;
}

export const BlogCallout: FC<BlogCalloutProps> = ({ slug, title, description, linkText }) => {
  const post = useContentfulBlogPost(slug);

  const altText = `Read the article: ${post.title}`;

  if (!post.featuredImage?.src) {
    throw new Error(`We require a featured image to have a BlogCallout for ${slug}!`);
  }

  return (
    <Container id={stringToAnchorId(title)}>
      <Section>
        <h2>{title}</h2>
        <p>{description || post.title}</p>

        <BlogLink to={slug} title={altText} linkText={linkText} />
      </Section>

      <SectionImage
        image={
          <BlogLink to={slug} title={altText}>
            <BlogPostImage src={post.featuredImage.src} alt={post.featuredImage.title || altText} />
          </BlogLink>
        }
      />
    </Container>
  );
};
