const SVGManagingClientPortfolios = () => (
  <svg
    width="604"
    height="363"
    viewBox="0 0 604 363"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic design of pie chart, cone and circles</title>
    <circle cx="68.6855" cy="134.262" r="25.9862" fill="url(#paint0_linear_1401_11317)" />
    <path d="M264.316 1.03979L602.762 182.209L264.469 357.49L264.316 1.03979Z" fill="#FFC21A" />
    <path
      d="M264.204 0.973792L602.65 182.206L264.356 357.552L264.204 0.973792Z"
      fill="url(#paint1_linear_1401_11317)"
    />
    <ellipse
      rx="44.2972"
      ry="178.753"
      transform="matrix(-1 -2.86035e-08 -6.6799e-08 1 261.086 179.302)"
      fill="url(#paint2_linear_1401_11317)"
    />
    <circle
      cx="139.183"
      cy="300.541"
      r="57.9513"
      transform="rotate(-4.1716 139.183 300.541)"
      fill="url(#paint3_linear_1401_11317)"
    />
    <circle cx="46.8466" cy="192.591" r="46.1591" fill="url(#paint4_linear_1401_11317)" />
    <path
      d="M239.818 118.053C239.818 145.843 217.29 168.371 189.501 168.371C161.711 168.371 139.184 145.843 139.184 118.053C139.184 90.2637 161.711 67.7358 189.501 67.7358C217.29 67.7358 239.818 90.2637 239.818 118.053Z"
      fill="#FC5C03"
    />
    <path
      d="M239.205 125.884C240.915 115.035 239.027 103.924 233.831 94.2478C228.635 84.5714 220.415 76.861 210.426 72.2934C200.438 67.7258 189.229 66.5518 178.511 68.9506C167.793 71.3495 158.154 77.1894 151.066 85.579L189.501 118.053L239.205 125.884Z"
      fill="#9C077B"
    />
    <path
      d="M145.228 141.964C149.454 149.789 155.678 156.354 163.267 160.991C170.856 165.627 179.538 168.17 188.429 168.359C197.32 168.549 206.103 166.378 213.882 162.069C221.662 157.76 228.16 151.466 232.715 143.828C237.271 136.19 239.721 127.482 239.815 118.589C239.91 109.696 237.646 100.937 233.254 93.2043C228.863 85.4712 222.5 79.0403 214.814 74.5665C207.128 70.0926 198.394 67.7358 189.5 67.7358L189.501 118.053L145.228 141.964Z"
      fill="#FFC21A"
    />
    <g style={{ mixBlendMode: 'multiply' }}>
      <circle
        cx="189.501"
        cy="118.053"
        r="50.3634"
        transform="rotate(-4.1716 189.501 118.053)"
        fill="url(#paint5_radial_1401_11317)"
      />
    </g>
    <circle cx="243.394" cy="180.177" r="42.2419" fill="url(#paint6_linear_1401_11317)" />
    <defs>
      <linearGradient
        id="paint0_linear_1401_11317"
        x1="85.3236"
        y1="120.468"
        x2="55.5139"
        y2="156.723"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#98A1F2" />
        <stop offset="1" stopColor="#202EAE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1401_11317"
        x1="355.482"
        y1="306.354"
        x2="320.095"
        y2="216.78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9900" />
        <stop offset="1" stopColor="#FFCA39" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1401_11317"
        x1="176.204"
        y1="178.753"
        x2="-10.5451"
        y2="178.753"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.635417" stopColor="#0819B9" />
        <stop offset="1" stopColor="#041578" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1401_11317"
        x1="199.169"
        y1="242.79"
        x2="100.885"
        y2="343.531"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBCFB7" />
        <stop offset="0.464391" stopColor="#FCAB7E" />
        <stop offset="1" stopColor="#FF5B00" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1401_11317"
        x1="93.0057"
        y1="146.432"
        x2="11.0412"
        y2="238.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCA39" />
        <stop offset="0.9999" stopColor="#FB7D22" />
        <stop offset="1" stopColor="#FF9839" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_1401_11317"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(218.114 120.14) rotate(-171.354) scale(80.0145 64.3227)"
      >
        <stop offset="0.453125" stopColor="#FFE6A5" stopOpacity="0" />
        <stop offset="1" stopColor="#F5C54C" />
      </radialGradient>
      <linearGradient
        id="paint6_linear_1401_11317"
        x1="266.086"
        y1="157.903"
        x2="216.129"
        y2="222.419"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCA39" />
        <stop offset="0.9999" stopColor="#FB7D22" />
        <stop offset="1" stopColor="#FF9839" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGManagingClientPortfolios;
