import styled, { css } from 'styled-components';

import { colorFromPropsMixin } from 'styled/helpers/colors';
import { NoLastChildMarginBottom } from 'styled/helpers/removeLastChildMarginBottom';
import { getDimension } from 'styled/helpers/getDimension';

import { Section } from '../Container/Section.styled';

import type { FC, ReactNode } from 'react';
import type { Color } from 'styled/types';
import type { SectionProps } from '../Container/Section.styled';

export interface CardProps extends SectionProps {
  center?: boolean;
  background?: Color;
  color?: Color;
  image?: ReactNode;
  title?: ReactNode;
  maxImageWidth?: number;
}

const Container = styled(Section)<CardProps>`
  ${colorFromPropsMixin}

  flex-basis: 300px;
  max-width: 450px;
  flex-shrink: 1;
  flex-grow: 1;

  padding: ${getDimension('size4')};
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `}
`;

const ImageContainer = styled.div<{ maxImageWidth?: number }>`
  display: flex;
  justify-content: center;
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  max-width: 175px;

  ${props =>
    props.maxImageWidth &&
    css`
      max-width: ${props.maxImageWidth}px;
    `}

  & + * {
    margin-top: ${getDimension('size8')};
  }
`;

export const Card: FC<CardProps> = ({
  background,
  center = true,
  color,
  children,
  title,
  image,
  maxImageWidth,
}) => (
  <Container color={color} background={background} center={center}>
    {image && <ImageContainer maxImageWidth={maxImageWidth}>{image}</ImageContainer>}
    {title && <h4>{title}</h4>}

    {/* Wrap in a div so margins don't collapse…but we also remove the final margin-bottom. */}
    <NoLastChildMarginBottom>{children}</NoLastChildMarginBottom>
  </Container>
);
