import * as ReactIs from 'react-is';
import { GatsbyImage } from 'gatsby-plugin-image';

import { isGatsbyImage } from 'helpers/isGatsbyImage';
import { stringToAnchorId } from 'helpers/stringToAnchorId';
import { isContentfulSVGImage } from 'helpers/isContentfulSVGImage';

import { RichText } from 'components/RichText';
import { Container, CardsContainer } from 'components/Container';

import { Card } from './Card.styled';
import { CardsHeader } from './CardsHeader.styled';
import { CardSubHeader } from './CardSubHeader.styled';

import type { FC, ReactNode } from 'react';
import type { Card as CardInterface } from 'hooks/useContentfulCards';
import type { ContainerProps, ContainerInnerProps } from 'components/Container';

export interface SimpleCard {
  cardIdentifier: string;
  title?: ReactNode;
  text?: ReactNode;

  // To merge with CardInterface, but disallow.
  contentfulId?: never;
  richText?: never;
  image?: ReactNode; // alternately, we can pass in a pre-rendered image
}
export interface CardsProps {
  center?: boolean;
  cards?: CardInterface[] | SimpleCard[];
  header?: ReactNode;
  subHeader?: ReactNode;
  verticalAlign?: ContainerProps['verticalAlign'];
  columns?: ContainerInnerProps['columns'];
  padding?: ContainerProps['padding'];
  maxImageWidth?: number;
}

/** A list of cards, defaults to 3 cards (columns) per row. */
export const Cards: FC<CardsProps> = ({
  cards,
  header,
  subHeader,
  verticalAlign = 'top',
  center = true,
  columns = 3,
  padding,
  maxImageWidth,
}) => {
  // We will not show 1 or 0 cards.
  if (!cards || cards.length <= 1) return null;

  return (
    <Container
      padding={padding}
      columns={columns}
      manageLayout={false}
      id={typeof header === 'string' ? stringToAnchorId(header) : undefined}
    >
      {header && <CardsHeader>{header}</CardsHeader>}
      {subHeader && <CardSubHeader>{subHeader}</CardSubHeader>}

      {(header || subHeader) && <br />}

      <CardsContainer verticalAlign={verticalAlign} columns={columns}>
        {cards.map(({ cardIdentifier, title, image, text, richText }) => {
          let cardImage: ReactNode | undefined;

          if (isGatsbyImage(image)) {
            cardImage = <GatsbyImage image={image.gatsbyImageData} alt={image.title} />;
          } else if (isContentfulSVGImage(image)) {
            cardImage = <img src={image.file.url} alt={image.title} />;
          } else if (ReactIs.isElement(image)) {
            cardImage = image;
          }

          return (
            <Card
              center={center}
              background="transparent"
              key={cardIdentifier}
              title={title}
              image={cardImage || null}
              maxImageWidth={maxImageWidth}
            >
              {text ? <p>{text}</p> : null}
              {richText ? <RichText richText={richText} /> : null}
            </Card>
          );
        })}
      </CardsContainer>
    </Container>
  );
};
