import Calculator from 'images/shared-page-assets/Calculator';
import ChartScatter from 'images/shared-page-assets/ChartScatter';
import Gauge from 'images/shared-page-assets/Gauge';

import type { Locale } from '@sharesight/gatsby-plugin-sharesight-localization';

export const getMutualFundCards = (mutualFund?: string) => [
  {
    cardIdentifier: 'mutual-fund-1',
    title: 'Swap your spreadsheet for Sharesight',
    text: `Reduce your investment portfolio admin with Sharesight’s automatic ${mutualFund} tracker.`,
    image: <Gauge />,
  },
  {
    cardIdentifier: 'mutual-fund-2',
    title: `See your ${mutualFund} dividend income`,
    text: 'Calculate your capital gains, tax, dividends, distributions and currency fluctuations.',
    image: <Calculator />,
  },
  {
    cardIdentifier: 'mutual-fund-3',
    title: `Benchmark against other ${mutualFund}s`,
    text: 'Compare against a world of investments – benchmark against other funds, stocks or ETFs.',
    image: <ChartScatter />,
  },
];

export const getBlogSlug = (currentLocale: Locale) => {
  if (currentLocale.id === 'us') return 'track-us-mutual-funds-with-sharesight';
  if (currentLocale.id === 'au' || currentLocale.id === 'nz') {
    return 'managed-funds-live-on-sharesight';
  }
  if (currentLocale.id === 'ca') {
    return 'track-canadian-mutual-funds-with-sharesight';
  }
  return 'what-is-a-mutual-fund';
};

export const getBlogCalloutTitle = (currentLocale: Locale) => {
  if (currentLocale.id === 'us') return 'We support 24,000+ US mutual funds';
  if (currentLocale.id === 'au' || currentLocale.id === 'nz') {
    return 'We support 12,700+ ANZ managed funds and mFunds';
  }
  if (currentLocale.id === 'ca') {
    return 'We support 40,000+ Canadian mutual funds';
  }
  return 'From the blog';
};
