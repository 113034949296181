import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { Container, HeroContainer, Section, SectionImage } from 'components/Container';
import { GatsbyLink, SignupLink } from 'components/Links';
import { SupportedBrokersBlock } from 'components/SupportedBrokersBlock';
import { PricingBlock } from 'components/PricingBlock';
import { Cards } from 'components/Cards';
import { BlogCallout } from 'components/BlogCallout';

import ClientPortfoliosImage from 'images/pricing/managing-client-portfolios';
import {
  getBlogCalloutTitle,
  getBlogSlug,
  getMutualFundCards,
} from 'page-data/mutual-fund-tracker';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const MutualFundTracker: FC<LocalizedPageProps> = () => {
  const {
    translations: { trackedInvestments, mutualFund, supportedBrokers, supportedMarkets, currency },
  } = useContentfulLocale();

  const { currentLocale } = useLocalizationContext();

  const mutualFundCards = getMutualFundCards(mutualFund);
  const blogSlug = getBlogSlug(currentLocale);
  const blogCalloutTitle = getBlogCalloutTitle(currentLocale);

  return (
    <Layout hideFooterCta>
      <Seo />
      <HeroContainer wrapMobile="hard">
        <Section>
          <h1>All your {mutualFund}s in one place</h1>
          <p>
            Automatically track your performance and dividends across {trackedInvestments}+{' '}
            {mutualFund}s, stocks and ETFs worldwide.
          </p>
          <br />
          <SignupLink asButton showArrowIcon>
            Sign up for free
          </SignupLink>
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'features/portfolio-hero',
            alt: "User Interface of Sharesight's portfolio and dividend tracker with property, investment, bitcoin and currency icons",
            loading: 'eager',
          })}
          boxShadow={false}
          borderRadius={false}
        />
      </HeroContainer>
      <Cards cards={mutualFundCards} />

      <Container>
        <Section>
          <h2>The best tool to track your {mutualFund}s</h2>
          <p>
            With Sharesight’s portfolio tracking tool, you can see all your {mutualFund}s in one
            place. We allow you to automatically track trades from {supportedBrokers}+ global
            brokers, making it easy to stay up to date on the performance of your funds (plus the
            rest of your investments), even across multiple brokers.
          </p>
        </Section>
        <SectionImage
          span={2}
          boxShadow={false}
          image={useGatsbyImage({
            name: 'mutual-fund-tracker/track-investments',
            alt: 'Best tool to track your managed funds and mutual funds',
          })}
        />
      </Container>
      <Container>
        <SectionImage
          span={2}
          boxShadow={false}
          image={useGatsbyImage({
            name: 'mutual-fund-tracker/stock-exchanges',
            alt: '60+ stock exchanges and fund providers supported by Sharesight',
          })}
        />

        <Section>
          <h2>Sharesight supports {supportedMarkets}+ stock exchanges and fund providers</h2>
          <p>
            Sharesight receives daily price and performance data for thousands of funds across
            Australia, New Zealand, Canada, the US, Luxembourg, Ireland and the UK, plus dozens of
            other leading stock exchanges worldwide. Check if we support your markets{' '}
            <GatsbyLink to="supported-stock-exchanges-managed-funds-mutual-funds">here</GatsbyLink>.
          </p>
        </Section>
      </Container>
      <SupportedBrokersBlock />
      <PricingBlock />
      <BlogCallout title={blogCalloutTitle} slug={blogSlug} />
      <Container background="navyBlue">
        <Section>
          <h2>Managing client portfolios?</h2>
          <p>
            Pay for what you need. Whether you track 5 portfolios or 500, you never pay more than{' '}
            {currency}13.50 per portfolio, per month.
          </p>
          <br />
          <GatsbyLink
            to="/business/#pricing"
            asButton
            appearance="secondary"
            containerBackground="negative"
            showArrowIcon
          >
            Business pricing
          </GatsbyLink>
        </Section>
        <SectionImage
          firstOnMobile
          image={<ClientPortfoliosImage />}
          boxShadow={false}
          borderRadius={false}
        />
      </Container>
    </Layout>
  );
};

export default MutualFundTracker;
